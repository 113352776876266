import { createSlice } from '@reduxjs/toolkit';
import { fetchEvents } from '../crm/thunks';

const eventsSlice = createSlice({
	name: 'events',
	initialState: {
		items: [],
		loading: false,
		error: null,
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchEvents.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(fetchEvents.fulfilled, (state, action) => {
				state.loading = false;
				state.items = action.payload;
			})
			.addCase(fetchEvents.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			});
	},
});

export const EventsReducer = eventsSlice.reducer;
