import { createSlice } from '@reduxjs/toolkit';
import { handleNewRoles } from './helpers';

const permissionsSlice = createSlice({
	name: 'business/permissions',
	initialState: {
		newRoles: null,
	},
	reducers: {
		setNewRoles: handleNewRoles,
	},
});

export const PermissionsReducer = permissionsSlice.reducer;
export const { setNewRoles } = permissionsSlice.actions;
