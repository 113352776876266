import { createSlice } from '@reduxjs/toolkit';
import { handleActiveIntegrations } from './helpers';

const integrationsSlice = createSlice({
	name: 'business/integrations',
	initialState: {
		activeIntegrations: null,
		deliveryCredentials: [],
	},
	reducers: {
		setActiveIntegrations: handleActiveIntegrations,
		setDeliveryCredentials(state, { payload }) {
			state.deliveryCredentials = payload;
		},
		removeDelivery(state, { payload }) {
			state.deliveryCredentials = state.deliveryCredentials.filter((item) => item.id !== payload);
		},
		updateLocalDelivery(state, { payload }) {
			const { id, updatedData } = payload;
			const index = state.deliveryCredentials.findIndex((delivery) => delivery.id === id);
			if (index !== -1) {
				state.deliveryCredentials[index] = {
					...state.deliveryCredentials[index],
					...updatedData,
				};
			}
		},
	},
	extraReducers: () => {},
});

export const IntegrationsReducer = integrationsSlice.reducer;
export const {
	setActiveIntegrations,
	setDeliveryCredentials,
	removeDelivery,
	updateLocalDelivery,
} = integrationsSlice.actions;
