import { createSlice } from '@reduxjs/toolkit';
import { fetchEventCategories } from '../crm/thunks';

const categoriesSlice = createSlice({
	name: 'categories',
	initialState: {
		items: [],
		loading: false,
		error: null,
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchEventCategories.pending, (state) => {
				console.log('Fetching categories...');
				state.loading = true;
				state.error = null;
			})
			.addCase(fetchEventCategories.fulfilled, (state, action) => {
				console.log('Fetched categories:', action.payload);
				state.loading = false;
				state.items = action.payload;
			})
			.addCase(fetchEventCategories.rejected, (state, action) => {
				console.error('Error fetching categories:', action.payload);
				state.loading = false;
				state.error = action.payload;
			});
	},
});

export const CategoriesReducer = categoriesSlice.reducer;
