import { createSlice } from '@reduxjs/toolkit';

const state = {
	permissions: null,
	stateChange: false,
	errorAuthorization: false,
	errorMsg: null,
	isLoading: false,
	user: null,
	loaded: false,
	firebaseToken: '',
	apiToken: '',
};

export const authSlice = createSlice({
	name: 'auth',
	initialState: state,
	reducers: {
		updateUserProfile: (state, { payload }) => ({
			...state,
			stateChange: payload.stateChange,
			loaded: true,
		}),
		authSignOut: () => ({ ...state, loaded: true }),
		authError: (state, { payload }) => ({
			...state,
			errorAuthorization: payload.updError,
			errorMsg: payload.errorMsg,
			loaded: true,
		}),
		isLoadingChange: (state, { payload }) => ({
			...state,
			isLoading: payload,
		}),
		updPermissions: (state, { payload }) => ({
			...state,
			permissions: payload,
			loaded: true,
		}),
		setLoggedUser: (state, { payload }) => ({
			...state,
			user: payload,
		}),
		setLoaded: (state) => {
			state.loaded = true;
		},
		setFirebaseToken: (state, { payload }) => {
			state.firebaseToken = payload;
		},
		setApiToken: (state, { payload }) => {
			state.apiToken = payload;
		},
	},
});
export const { setLoaded, setFirebaseToken, setApiToken } = authSlice.actions;
