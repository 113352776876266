const translations = {
	en: {
		//sidebar

		addDeal: 'Add Deal',
		addFunnel: 'Add Funnel',
		dashboard: 'Dashboard',
		crm: 'CRM',
		deals: 'Deals',
		integrations: 'Integrations',
		clientsChat: 'Client Chat',
		clientsMenu: 'Clients',
		clientBase: 'Client Base',
		categoryList: 'Category List',
		catalog: 'Catalog',
		categories: 'Goods Categories',
		products: 'Products',
		characteristics: 'Characteristics',
		manufacturers: 'Manufacturers',
		calendar: 'Calendar',
		analytics: 'Analytics',
		tasks: 'Tasks',
		ordersTable: 'Orders',
		sevices: 'Sevices',
		aiManager: 'AI Manager',
		assistants: 'Assistants',
		channels: 'Channels',
		knowledgeBase: 'Knowledge Base',
		orgStructure: 'Org Structure',
		departments: 'Departments',
		personnel: 'Staff',
		allPersonnel: 'All Staff',
		schedule: 'Schedule',
		staffRequests: 'Staff Requests',
		settings: 'Settings',
		generalInfo: 'General Information',
		admins: 'Administrators',
		faq: 'FAQ',
		specialDays: 'Special Days',
		companies: 'Companies',
		permissionsAndAccess: 'Roles and Permissions',
		pageAccess: 'Page Access',
		roleAccess: 'Role Access',
		roles: 'Roles',
		archivedLeads: 'Archived Leads',
		archive: 'Archive',
		archivedDeals: 'Archived Deals',
		archivedServices: 'Archived Services',

		//header
		myCommunity: 'My Community',
		help: 'Help',
		hideBlock: 'Hide Block',
		logout: 'Logout',
		noFullName: 'Full Name not specified',

		//coming soon
		comingSoon: 'This feature will be available soon',

		//dashboard
		controlPanel: 'Control Panel',
		joinTesting: 'Join testing',
		andSurveys: 'and surveys',
		learnMore: 'Learn more',
		store: 'Store',
		active: 'Active',
		rating: 'Rating',
		complaints: 'Complaints',
		delivery: 'Delivery',
		overallRating: 'Overall Rating',
		advertising: 'Advertising',

		//dashboard orders
		new: 'New',
		inProcessing: 'In Processing',
		delivering: 'Delivering',
		successful: 'Successful',
		unsuccessful: 'Unsuccessful',
		clientThinking: 'Client Thinking',
		clientScheduled: 'Client Scheduled',
		repeatSale: 'Repeat Sale',

		//OrdersGoods
		ordersGoods: 'Orders Goods',
		viewAll: 'VIEW ALL',

		//dashboard Notifications
		notifications: 'Notifications',
		orderQuestions: 'Orders Questions',
		sellerQuestions: 'Questions to Seller',
		callCenter: 'Call Center Inquiries',
		noNew: 'No New',

		//dashboard PopularGoods
		popularGoods: 'Popular Goods',
		product: 'Product',
		price: 'Price',
		sold: 'Sold',
		productName: 'Product Name',
		inStock: 'In Stock',

		//dashboard Reviews
		reviews: 'Reviews',
		aboutProducts: 'About Products',
		aboutStore: 'About Store',
		questions: 'Questions',
	},
	uk: {
		//sidebar
		addFunnel: 'Додати воронку',
		addDeal: 'Додати угоду',
		dashboard: 'Панель управління',
		crm: 'CRM',
		deals: 'Угоди',
		integrations: 'Інтеграції',
		clientsChat: 'Чат з клієнтами',
		clientsMenu: 'Клієнти',
		clientBase: 'Клієнтська база',
		categoryList: 'Список категорій',
		catalog: 'Каталог',
		categories: 'Категорії Товарiв',
		products: 'Товари',
		characteristics: 'Характеристики',
		manufacturers: 'Виробники',
		sevices: 'Послуги',
		calendar: 'Календар',
		analytics: 'Аналітика',
		tasks: 'Задачі',
		ordersTable: 'Замовлення',
		aiManager: 'AI менеджер',
		assistants: 'Асистенти',
		channels: 'Канали',
		knowledgeBase: 'База знань',
		orgStructure: 'ОРГ Структура',
		departments: 'Вiддiли',
		personnel: 'Персонал',
		allPersonnel: 'Весь персонал',
		schedule: 'Розклад',
		staffRequests: 'Запити персоналу',
		settings: 'Налаштування',
		generalInfo: 'Загальна інформація',
		admins: 'Адміністратори',
		faq: 'Питання/Відповіді',
		specialDays: 'Спеціальні дні',
		companies: 'Компанії',
		permissionsAndAccess: 'Ролі та доступи',
		pageAccess: 'Доступи до сторінок',
		roleAccess: 'Доступи до ролей',
		roles: 'Ролі',
		archivedLeads: 'Архівні ліди',
		archive: 'Архів',
		archivedDeals: 'Архівні угоди',
		archivedServices: 'Архівні послуги',

		//header
		myCommunity: 'Моя громада',
		help: 'Допомога',
		hideBlock: 'Скрити блок',
		logout: 'Вийти',
		noFullName: 'ПІБ не вказано',

		//cooming soon
		comingSoon: 'Скоро ця функція стане доступна',

		//dashboard
		controlPanel: 'Панель управління',
		joinTesting: 'Приєднуйтесь до тестувань',
		andSurveys: 'та опитувань',
		learnMore: 'Детальніше',
		store: 'Магазин',
		active: 'Активний',
		rating: 'Рейтинг',
		complaints: 'Скарги',
		delivery: 'Доставка',
		overallRating: 'Загальний рейтинг',
		advertising: 'Реклама',

		//dashboard orders
		new: 'Нові',
		inProcessing: 'В обробці',
		delivering: 'Доставляються',
		successful: 'Успішні',
		unsuccessful: 'Неуспішні',
		clientThinking: 'Клієнт думає',
		clientScheduled: 'Клієнт записаний',
		repeatSale: 'Повторний продаж',

		//OrdersGoods
		ordersGoods: 'Замовлення товари',
		viewAll: 'ДИВИТИСЬ ВСІ',

		//dashboard Notifications
		notifications: 'Повідомлення',
		orderQuestions: 'Питання про замовлення',
		sellerQuestions: 'Запитання продавцю',
		callCenter: 'Звернення в колл-центр',
		noNew: 'Немає нових',

		//dashboard PopularGoods
		popularGoods: 'Популярні товари',
		product: 'Товар',
		price: 'Ціна',
		sold: 'Продано',
		productName: 'Назва товару',
		inStock: 'В наявності',

		//dashboard Reviews
		reviews: 'Повідомлення',
		aboutProducts: 'Про товари',
		aboutStore: 'Про магазин',
		questions: 'Питання',
	},
	ru: {
		//sidebar
		addFunnel: 'Добавить воронку',
		addDeal: 'Добавить сделку',
		dashboard: 'Панель управления',
		crm: 'CRM',
		deals: 'Сделки',
		integrations: 'Интеграции',
		clientsChat: 'Чат с клиентами',
		clientsMenu: 'Клиенты',
		clientBase: 'Клиентская база',
		categoryList: 'Список категорий',
		catalog: 'Каталог',
		categories: 'Категории Товаров',
		products: 'Товары',
		characteristics: 'Характеристики',
		manufacturers: 'Производители',
		sevices: 'Услуги',
		calendar: 'Календарь',
		analytics: 'Аналитика',
		tasks: 'Задачи',
		ordersTable: 'Заказы',
		aiManager: 'AI менеджер',
		assistants: 'Ассистенты',
		channels: 'Каналы',
		knowledgeBase: 'База знаний',
		orgStructure: 'ОРГ Структура',
		departments: 'Отделы',
		personnel: 'Персонал',
		allPersonnel: 'Весь персонал',
		schedule: 'Расписание',
		staffRequests: 'Запросы',
		settings: 'Настройки',
		generalInfo: 'Общая информация',
		admins: 'Администраторы',
		faq: 'Вопросы/Ответы',
		specialDays: 'Специальные дни',
		companies: 'Компании',
		permissionsAndAccess: 'Роли и доступы',
		pageAccess: 'Доступы к страницам',
		roleAccess: 'Доступы к ролям',
		roles: 'Роли',
		archivedLeads: 'Архивные лиды',
		archive: 'Архив',
		archivedDeals: 'Архивные сделки',
		archivedServices: 'Архивные услуги',
		//header
		noFullName: 'ФИО не указано',
		myCommunity: 'Моя громада',
		help: 'Помощь',
		hideBlock: 'Скрыть блок',
		logout: 'Выйти',

		// comming soon
		comingSoon: 'Скоро эта функция станет доступна',

		//dashboard
		controlPanel: 'Панель управления',
		joinTesting: 'Присоединяйтесь к тестированиям',
		andSurveys: 'и опросам',
		learnMore: 'Подробнее',
		store: 'Магазин',
		active: 'Активный',
		rating: 'Рейтинг',
		complaints: 'Жалобы',
		delivery: 'Доставка',
		overallRating: 'Общий рейтинг',
		advertising: 'Реклама',

		//dashboard orders
		new: 'Новые',
		inProcessing: 'В обработке',
		delivering: 'Доставляются',
		successful: 'Успешные',
		unsuccessful: 'Неуспешные',
		clientThinking: 'Клиент думает',
		clientScheduled: 'Клиент записан',
		repeatSale: 'Повторная продажа',

		//OrdersGoods
		ordersGoods: 'Заказы товары',
		viewAll: 'СМОТРЕТЬ ВСЕ',

		//dashboard Notifications
		notifications: 'Уведомления',
		orderQuestions: 'Вопросы о заказах',
		sellerQuestions: 'Вопросы продавцу',
		callCenter: 'Обращения в колл-центр',
		noNew: 'Нет новых',

		//dashboard PopularGoods
		popularGoods: 'Популярные товары',
		product: 'Товар',
		price: 'Цена',
		sold: 'Продано',
		productName: 'Название товара',
		inStock: 'В наличии',

		//dashboard Reviews
		reviews: 'Сообщения',
		aboutProducts: 'О товарах',
		aboutStore: 'О магазине',
		questions: 'Вопросы',
	},
};

export default translations;
