import { createSlice } from '@reduxjs/toolkit';
import { handlesetDepartments, handleModalDelete } from './helpers';

const DepartmentsSlice = createSlice({
	name: 'business/departments',
	initialState: {
		departmentsList: null,
		isModalDeleteOpen: false,
	},
	reducers: {
		setDepartments: handlesetDepartments,
		setIsModalDeleteOpen: handleModalDelete,
	},
	extraReducers: () => {},
});

export const DepartmentsReducer = DepartmentsSlice.reducer;

export const { setDepartments, setIsModalDeleteOpen } = DepartmentsSlice.actions;
