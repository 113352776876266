import translations from 'components/AdminHeader/components/Translations/translations';

export const getFullName = (item, language) => {
	const t = translations[language] || {};
	const noFullName = t.noFullName || 'No full name specified';

	if (!item?.lastName && !item?.firstName && !item?.thirdName) {
		return noFullName;
	} else {
		return (item?.lastName || '') + ' ' + (item?.firstName || '') + ' ' + (item?.thirdName || '');
	}
};
