import { createSlice } from '@reduxjs/toolkit';
import {
	handleConnectedChats,
	handleAllTelegramChats,
	handleSelectedChatMessages,
	handleUnreadCountMessages,
	handleNewMessages,
} from './helpers';

const chatsSlice = createSlice({
	name: 'business/chats',
	initialState: {
		connectedChats: null,
		allTelegramChats: null,
		selectedChatMessages: null,
		unreadCountMessages: 0,
		newMessages: null,
	},
	reducers: {
		setConnectedChats: handleConnectedChats,
		setAllTelegramChats: handleAllTelegramChats,
		setSelectedChatMessages: handleSelectedChatMessages,
		setUnreadCountMessages: handleUnreadCountMessages,
		setNewMessages: handleNewMessages,
	},
});

export const ChatsReducer = chatsSlice.reducer;
export const {
	setConnectedChats,
	setAllTelegramChats,
	setSelectedChatMessages,
	setUnreadCountMessages,
	setNewMessages,
} = chatsSlice.actions;
