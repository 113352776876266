import { createSlice } from '@reduxjs/toolkit';
import {
	handleActiveBusiness,
	handleActiveUser,
	handleExchangeRate,
	handleActiveStaff,
} from './helpers';

const BusinessSlice = createSlice({
	name: 'business',
	initialState: {
		activeBusiness: null,
		activeUser: null,
		activeStaff: null,
		exchangeRate: null,
		language: 'uk',
	},
	reducers: {
		setActiveBusiness: handleActiveBusiness,
		setActiveUser: handleActiveUser,
		setActiveStaff: handleActiveStaff,
		setExchangeRate: handleExchangeRate,
		setResetBusinessState: () => {},
		setLanguage: (state, { payload }) => {
			state.language = payload;
		},
	},
	extraReducers: () => {},
});

export const BusinessReducer = BusinessSlice.reducer;
export const {
	setActiveBusiness,
	setLanguage,
	setActiveUser,
	setActiveStaff,
	setExchangeRate,
	setResetBusinessState,
} = BusinessSlice.actions;
