import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Loadable } from './Loadable';

export default function BusinessModuleRouter() {
	return (
		<Routes>
			<Route path="/:id" element={<BusinessModuleLayout />}>
				<Route index element={<ControlPanelPage />} />
				{/* <Route
					path="orders"
					element={
						<ModalFilterProvider>
							<OrdersPage />
						</ModalFilterProvider>
					}> */}
				{/* CRM */}
				<Route path="crm" element={<CRMPage />} />
				<Route path="crm/:crmId" element={<SomeCRMPage />} />
				<Route path="crm/:crmId/:leadId" element={<LeadDetailsPage />} />
				{/* Deals */}
				<Route path="deals" element={<DealsPage />} />
				<Route path="deals/:dealId" element={<SomeDealsPage />} />
				<Route path="deals/:dealId/:leadId" element={<DealDetailsPage />} />

				{/* Integrations */}
				<Route path="integrations/marketplace" element={<IntegrationsPage />} />
				<Route path="integrations/add" element={<AddIntegrationsPage />} />
				<Route path="integrations/communication-channels" element={<CommunicationChannelsPage />} />

				<Route path="all" element={<AllOrdersPage />} />
				<Route path="all/:leadId" element={<LeadDetailsPage />} />
				<Route path="create" element={<CreateOrderPage />} />

				{/* Clients chat*/}
				<Route path="clients-chat" element={<ClientsChatPage />} />

				<Route path="reviews" element={<ReviewsPage />} />
				<Route path="goods" element={<GoodsPage />}>
					<Route path="active" element={<ActiveGoodsPage />} />
					<Route path="for-confirmation" element={<ForConfirmationGoodsPage />} />
					<Route path="moderation" element={<ModerationGoodsPage />} />
					<Route path="new" element={<NewGoodsPage />} />
					<Route path="not-active" element={<NotActiveGoodsPage />} />
				</Route>
				<Route path="product-management" element={<ProductManagementPage />} />
				<Route path="balance" element={<MyBalancePage />}>
					<Route path="invoices" element={<InvoicesForPaymentPage />} />
					<Route path="reports" element={<ReportsPage />} />
					<Route path="tariff-history" element={<TariffHistoryPage />} />
					<Route path="tax" element={<TaxPage />} />
					<Route path="transaction-history" element={<TransactionHistoryPage />} />
				</Route>
				<Route path="payment-management" element={<PaymentManagementPage />} />
				<Route path="advertising" element={<AdvertisingPage />}>
					<Route path="companies" element={<CompaniesPage />} />
					<Route path="kits" element={<KitsPage />} />
					<Route path="product-promotion" element={<ProductsPromotionPage />} />
					<Route path="promotional" element={<PromotionalPage />} />
				</Route>
				<Route path="settings" element={<SettingsPage />}>
					<Route path="managers" element={<ManagersPage />} />
					<Route path="notifications" element={<NotificationsPage />} />
					<Route path="payments" element={<PaymentsPage />} />
					<Route path="profile" element={<ProfilePage />} />
					<Route path="prro" element={<PRROPage />} />
					<Route path="schedule" element={<SchedulePage />} />
					<Route path="store" element={<StorePage />} />
				</Route>
				<Route path="modules" element={<ModulesPage />}>
					<Route path="novaposhta" element={<NovaPoshtaPage />} />
					<Route path="meestposhta" element={<MeestPoshtaPage />} />
					<Route path="ukrposhta" element={<UkrPoshtaPage />} />
					<Route path="rozetka" element={<RozetkaPage />} />
				</Route>
				<Route path="contacts" element={<ContactsPage />} />
				<Route path="whatnew" element={<WhatNew />} />
				<Route path="instruments" element={<InstrumentsPage />} />
				<Route path="document-flow" element={<DocumentFlowPage />} />
				<Route path="clients" element={<ClientsPage />} />
				<Route path="clients/add" element={<AddEditClient />} />
				<Route path="clients/add/:idClient" element={<AddEditClient />} />
				<Route path="catalog" element={<CatalogPage />}>
					<Route path="categories" element={<CategoriesPage />} />
					<Route path="categories/:idCategory" element={<EditAddCategory />} />
					<Route path="categories/add" element={<EditAddCategory />} />
					<Route path="products" element={<ProductsPage />} />
					<Route path="periodicPayments" element={<PeriodicPaymentsPage />} />
					<Route path="periodicPayments/add" element={<EditAddPeriodicPayments />} />
					<Route path="periodicPayments/add/:idPayments" element={<EditAddPeriodicPayments />} />
					<Route path="ocFilter" element={<OcFilterPage />} />
					<Route path="pages" element={<PagesPage />} />
					<Route path="pages/add" element={<AddEditPages />} />
					<Route path="pages/add/:idPages" element={<AddEditPages />} />

					<Route path="filters" element={<FiltersPage />} />
					<Route path="filters/addFilters" element={<AddEditFilters />} />
					<Route path="filters/addFilters/:idFilter" element={<AddEditFilters />} />
					<Route path="settings" element={<CatalogSettingsPage />} />
					<Route path="characteristics" element={<CharacteristicsPage />} />
					<Route path="characteristics/addCharacteristics" element={<AddEditCharacteristics />} />
					<Route
						path="characteristics/addCharacteristics/:idCharacteristics"
						element={<AddEditCharacteristics />}
					/>
					<Route path="options" element={<OptionsPage />} />
					<Route path="options/add" element={<EditAddOption />} />
					<Route path="options/add/:idOptions" element={<EditAddOption />} />
					<Route path="manufacturers" element={<ManufacturesPage />} />
					<Route path="manufacturers/add" element={<AddEditManufactures />} />
					<Route path="manufacturers/add/:idManufactures" element={<AddEditManufactures />} />

					<Route path="companies" element={<CompaniesCatalogPage />} />
					<Route path="companies/add" element={<AddEditCompanies />} />
					<Route path="companies/add/:idCompanies" element={<AddEditCompanies />} />

					<Route path="clients-categories" element={<ClientsCategoriesPage />} />
					<Route path="clients-categories/add" element={<AddEditClientsCategory />} />
					<Route path="clients-categories/addEvent" element={<AddEditClientsCategory />} />
					<Route
						path="clients-categories/add/:idClientsCategory"
						element={<AddEditClientsCategory />}
					/>

					<Route path="filesForDownload" element={<FilesForDownloadPage />} />
					<Route path="filesForDownload/add" element={<AddEditFileForDownload />} />
					<Route path="filesForDownload/add/:idFile" element={<AddEditFileForDownload />} />
					<Route path="reviews" element={<CatalogReviewsPage />} />
					<Route path="reviews/add" element={<AddEditReviews />} />
					<Route path="articles" element={<ArticlesPage />} />
					<Route path="articles/add" element={<AddEditArticle />} />
					<Route path="articles/add/:idArticle" element={<AddEditArticle />} />
					<Route path="analytics" element={<AnalyticsPage />} />
					<Route path="sevices" element={<SevicesPage />} />
					<Route path="sevices/add" element={<AddEditServices />} />
					<Route path="sevices/edit/:idService" element={<AddEditServices />} />

					<Route path="tasks" element={<TasksPage />} />
				</Route>

				<Route path="calendar" element={<CalendarWrapper />}>
					<Route path="view" element={<CalendarPage />} />
					<Route path="event-list" element={<EventsList />} />
				</Route>

				{/* Замовлення */}
				<Route path="orders-table" element={<OrdersTable />} />
				<Route path="orders-table/add" element={<OrdersAddForm />} />
				<Route path="orders-table/edit/:id" element={<OrdersAddForm />} />

				{/* AI менеджер */}
				<Route path="ai" element={<AIPage />}>
					<Route path="assistants" element={<AssistantsPage />} />
					<Route path="assistants/:assistantId" element={<AddEditAssistant />} />
					<Route path="knowledge-base" element={<KnowledgeBasePage />} />
				</Route>

				{/* Налаштування */}
				<Route path="sett" element={<SettPage />}>
					<Route path="common-settings" element={<BusinessSettings />} />
					<Route path="special-days" element={<SpecialDays />} />
				</Route>

				{/* Staff */}
				<Route path="staff" element={<AllStaff />} />
				<Route path="staff/edit" element={<EditProfile />} />
				<Route path="schedule" element={<BusinessSchedule />} />
				<Route path="staff-requests" element={<StaffRequestsList />} />
				{/* Departments */}
				<Route path="departments" element={<BusinessDepartments />} />
				<Route path="departments/edit" element={<EditDepartment />} />
				{/* Permissions */}
				<Route path="permissions" element={<PermissionsPage />}>
					<Route path="access" element={<AccessPage />} />
					<Route path="roles" element={<RolesPage />} />
					<Route path="settings" element={<SettingsRolesPage />} />
				</Route>

				{/* Archive */}
				<Route path="archive" element={<ArchievedPage />}>
					<Route path="archive-leads" element={<ArchievedPage />} />
					<Route path="archive-deals" element={<OrdersAddForm />} />
					<Route path="archive-orders" element={<OrdersAddForm />} />
				</Route>

				<Route path="help" element={<HowToUseList />} />
				<Route path="help/:id/:suggestionId" element={<HowToUseItem />} />
			</Route>
		</Routes>
	);
}

const CommunicationChannelsPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/CommunicationChannelsPage'))
);

const SettingsRolesPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/PermissionsPage/pages/SettingsRolesPage'))
);
const AccessPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/PermissionsPage/pages/AccessPage'))
);
const RolesPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/PermissionsPage/pages/RolesPage'))
);
const PermissionsPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/PermissionsPage'))
);
const EditDepartment = Loadable(
	lazy(
		() =>
			import(
				'components/BusinessModule/pages/DepartmentsPage/components/tabs/general/EditDepartment'
			)
	)
);

const BusinessDepartments = Loadable(
	lazy(() => import('components/BusinessModule/pages/DepartmentsPage'))
);

const StaffRequestsList = Loadable(
	lazy(() => import('components/BusinessModule/pages/StaffPage/StaffRequests/StaffRequestsList'))
);

const BusinessSchedule = Loadable(
	lazy(() => import('components/BusinessModule/pages/StaffPage/Schedule/BusinessSchedule'))
);

const EditProfile = Loadable(
	lazy(() => import('components/BusinessModule/pages/StaffPage/AllStaff/EditProfile/index.jsx'))
);

const AllStaff = Loadable(lazy(() => import('components/BusinessModule/pages/StaffPage/AllStaff')));

const SettPage = Loadable(lazy(() => import('components/BusinessModule/pages/Settings')));

const BusinessSettings = Loadable(
	lazy(
		() =>
			import('components/BusinessModule/pages/Settings/EditFormBusinessSettings/BusinessSettings')
	)
);

const SpecialDays = Loadable(
	lazy(() => import('components/BusinessModule/pages/Settings/SpecialDays/SpecialDays'))
);

const KnowledgeBasePage = Loadable(
	lazy(() => import('components/BusinessModule/pages/AIPage/pages/KnowledgeBasePage'))
);

const BusinessModuleLayout = Loadable(
	lazy(() => import('components/BusinessModule/layouts/BusinessModuleLayout'))
);
const ContactsPage = Loadable(lazy(() => import('components/BusinessModule/pages/ContactsPage')));
const WhatNew = Loadable(lazy(() => import('components/BusinessModule/pages/WhatNew')));
const InstrumentsPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/InstrumentsPage'))
);
const ControlPanelPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/ControlPanelPage'))
);
const OrdersPage = Loadable(lazy(() => import('components/BusinessModule/pages/OrdersPage')));

const AllOrdersPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/OrdersPage/pages/AllOrdersPage'))
);
const SomeStatusOrdersPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/OrdersPage/pages/SomeStatusOrdersPage'))
);
const CreateOrderPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/OrdersPage/pages/CreateOrderPage'))
);
const ClientsChatPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/ClientsChatPage'))
);

const ReviewsPage = Loadable(lazy(() => import('components/BusinessModule/pages/ReviewsPage')));
const GoodsPage = Loadable(lazy(() => import('components/BusinessModule/pages/GoodsPage')));
const MyBalancePage = Loadable(lazy(() => import('components/BusinessModule/pages/MyBalancePage')));
const InvoicesForPaymentPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/MyBalancePage/pages/InvoicesForPaymentPage'))
);
const ReportsPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/MyBalancePage/pages/ReportsPage'))
);
const TariffHistoryPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/MyBalancePage/pages/TariffHistoryPage'))
);
const TaxPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/MyBalancePage/pages/TaxPage'))
);
const TransactionHistoryPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/MyBalancePage/pages/TransactionHistoryPage'))
);
const ProductManagementPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/ProductManagementPage'))
);
const PaymentManagementPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/PaymentManagementPage'))
);
const AdvertisingPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/AdvertisingPage'))
);
const CompaniesPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/AdvertisingPage/pages/CompaniesPage'))
);
const KitsPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/AdvertisingPage/pages/KitsPage'))
);
const ProductsPromotionPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/AdvertisingPage/pages/ProductsPromotionPage'))
);
const PromotionalPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/AdvertisingPage/pages/PromotionalPage'))
);
const SettingsPage = Loadable(lazy(() => import('components/BusinessModule/pages/SettingsPage')));
const ManagersPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/SettingsPage/pages/ManagersPage'))
);
const NotificationsPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/SettingsPage/pages/NotificationsPage'))
);
const PaymentsPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/SettingsPage/pages/PaymentsPage'))
);
const ProfilePage = Loadable(
	lazy(() => import('components/BusinessModule/pages/SettingsPage/pages/ProfilePage'))
);
const PRROPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/SettingsPage/pages/PRROPage'))
);
const SchedulePage = Loadable(
	lazy(() => import('components/BusinessModule/pages/SettingsPage/pages/SchedulePage'))
);
const StorePage = Loadable(
	lazy(() => import('components/BusinessModule/pages/SettingsPage/pages/StorePage'))
);
const ModulesPage = Loadable(lazy(() => import('components/BusinessModule/pages/ModulesPage')));
const DocumentFlowPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/DocumentFlowPage'))
);
const ActiveGoodsPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/GoodsPage/pages/ActiveGoodsPage'))
);
const ForConfirmationGoodsPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/GoodsPage/pages/ForConfirmationGoodsPage'))
);
const ModerationGoodsPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/GoodsPage/pages/ModerationGoodsPage'))
);
const NewGoodsPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/GoodsPage/pages/NewGoodsPage'))
);
const NotActiveGoodsPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/GoodsPage/pages/NotActiveGoodsPage'))
);
const ModalFilterProvider = Loadable(
	lazy(() => import('components/BusinessModule/pages/OrdersPage/context/ModalFilterProvider'))
);
const NovaPoshtaPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/ModulesPage/pages/NovaPoshtaPage'))
);
const MeestPoshtaPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/ModulesPage/pages/MeestPoshtaPage'))
);
const UkrPoshtaPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/ModulesPage/pages/UkrPoshtaPage'))
);
const RozetkaPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/ModulesPage/pages/RozetkaPage'))
);

const OrdersTable = Loadable(lazy(() => import('components/BusinessModule/pages/OrdersTable')));

const OrdersAddForm = Loadable(
	lazy(() => import('components/BusinessModule/pages/OrdersTable/components/OrdersAddForm'))
);

const CatalogPage = Loadable(lazy(() => import('components/BusinessModule/pages/CatalogPage')));

const ProductsPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/CatalogPage/pages/ProductsPage'))
);
const PeriodicPaymentsPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/CatalogPage/pages/PeriodicPaymentsPage'))
);
const OcFilterPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/CatalogPage/pages/OcFilterPage'))
);
const PagesPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/CatalogPage/pages/PagesPage'))
);
const FiltersPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/CatalogPage/pages/FiltersPage'))
);
const CatalogSettingsPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/CatalogPage/pages/SettingsPage'))
);
const CharacteristicsPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/CatalogPage/pages/CharacteristicsPage'))
);
const OptionsPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/CatalogPage/pages/OptionsPage'))
);
const ManufacturesPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/CatalogPage/pages/ManufacturesPage'))
);

const CompaniesCatalogPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/CatalogPage/pages/CompaniesCatalogPage'))
);

const FilesForDownloadPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/CatalogPage/pages/FilesForDownloadPage'))
);
const CatalogReviewsPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/CatalogPage/pages/ReviewsPage'))
);
const ArticlesPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/CatalogPage/pages/ArticlesPage'))
);

const CategoriesPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/CatalogPage/pages/CategoriesPage'))
);

const CalendarWrapper = lazy(
	() => import('components/BusinessModule/pages/CalendarPage/CalendarWrapper')
);

const CalendarPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/CalendarPage/components/CalendarTable'))
);

const EventsList = lazy(
	() => import('components/BusinessModule/pages/CalendarPage/components/EventList/EventsList')
);

const AnalyticsPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/CatalogPage/pages/AnalyticsPage'))
);

const SevicesPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/CatalogPage/pages/SevicesPage'))
);

const AddEditServices = Loadable(
	lazy(
		() =>
			import(
				'components/BusinessModule/pages/CatalogPage/pages/SevicesPage/components/AddEditServices/AddEditServices'
			)
	)
);

const TasksPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/CatalogPage/pages/TasksPage'))
);
const EditAddCategory = Loadable(
	lazy(
		() =>
			import(
				'components/BusinessModule/pages/CatalogPage/pages/CategoriesPage/components/EditAddCategory'
			)
	)
);
const EditAddPeriodicPayments = Loadable(
	lazy(
		() =>
			import(
				'components/BusinessModule/pages/CatalogPage/pages/PeriodicPaymentsPage/components/EditAddPeriodicPayments'
			)
	)
);
const EditAddOption = Loadable(
	lazy(
		() =>
			import(
				'components/BusinessModule/pages/CatalogPage/pages/OptionsPage/components/EditAddOption'
			)
	)
);
const AddEditCharacteristics = Loadable(
	lazy(
		() =>
			import(
				'components/BusinessModule/pages/CatalogPage/pages/CharacteristicsPage/components/AddEditCharacteristics'
			)
	)
);
const AddEditFilters = Loadable(
	lazy(
		() =>
			import(
				'components/BusinessModule/pages/CatalogPage/pages/FiltersPage/components/AddEditFilters'
			)
	)
);
const AddEditManufactures = Loadable(
	lazy(
		() =>
			import(
				'components/BusinessModule/pages/CatalogPage/pages/ManufacturesPage/components/AddEditManufactures'
			)
	)
);

const AddEditCompanies = Loadable(
	lazy(
		() =>
			import(
				'components/BusinessModule/pages/CatalogPage/pages/CompaniesCatalogPage/components/AddEditCompanies'
			)
	)
);

const AddEditFileForDownload = Loadable(
	lazy(
		() =>
			import(
				'components/BusinessModule/pages/CatalogPage/pages/FilesForDownloadPage/component/AddEditFileForDownload/AddEditFileForDownload'
			)
	)
);
const AddEditArticle = Loadable(
	lazy(
		() =>
			import(
				'components/BusinessModule/pages/CatalogPage/pages/ArticlesPage/components/AddEditArticle/AddEditArticle'
			)
	)
);
const ClientsPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/CatalogPage/pages/ClientsPages/ClientsPage'))
);

const AddEditClient = Loadable(
	lazy(
		() =>
			import(
				'components/BusinessModule/pages/CatalogPage/pages/ClientsPages/ClientsPage/components/AddEditClient/AddEditClient'
			)
	)
);

const ClientsCategoriesPage = Loadable(
	lazy(
		() =>
			import('components/BusinessModule/pages/CatalogPage/pages/ClientsPages/ClientsCategoriesPage')
	)
);
const AddEditClientsCategory = Loadable(
	lazy(
		() =>
			import(
				'components/BusinessModule/pages/CatalogPage/pages/ClientsPages/ClientsCategoriesPage/components/AddEditClientsCategory/AddEditClientsCategory'
			)
	)
);

const AddEditPages = Loadable(
	lazy(
		() =>
			import(
				'components/BusinessModule/pages/CatalogPage/pages/PagesPage/components/AddEditPages/AddEditPages'
			)
	)
);

const AddEditReviews = Loadable(
	lazy(
		() =>
			import(
				'components/BusinessModule/pages/CatalogPage/pages/ReviewsPage/components/AddEditReviews/AddEditReviews'
			)
	)
);

const CRMPage = Loadable(lazy(() => import('components/BusinessModule/pages/CRMPage')));
const SomeCRMPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/CRMPage/pages/SomeCRMPage'))
);
const LeadDetailsPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/CRMPage/pages/LeadDetailsPage'))
);

const DealsPage = Loadable(lazy(() => import('components/BusinessModule/pages/DealsPage')));
const SomeDealsPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/DealsPage/pages/SomeDealsPage'))
);
const DealDetailsPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/DealsPage/pages/DealDetailsPage'))
);

const IntegrationsPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/IntegrationsPage'))
);
const AddIntegrationsPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/IntegrationsPage/pages/AddIntegrationsPage'))
);

const AIPage = Loadable(lazy(() => import('components/BusinessModule/pages/AIPage')));

const AssistantsPage = Loadable(
	lazy(() => import('components/BusinessModule/pages/AIPage/pages/AssistantsPage'))
);

const AddEditAssistant = Loadable(
	lazy(
		() =>
			import(
				'components/BusinessModule/pages/AIPage/pages/AssistantsPage/components/AddEditAssistant'
			)
	)
);

const HowToUseList = Loadable(
	lazy(() => import('components/BusinessModule/pages/HowToUse/HowToUseList'))
);

const HowToUseItem = Loadable(
	lazy(() => import('components/BusinessModule/pages/HowToUse/HowToUseItem'))
);

const ArchievedPage = Loadable(lazy(() => import('components/BusinessModule/pages/ArchivePage')));

// const ArchievedLeads = Loadable(
// 	lazy(() => import('components/BusinessModule/pages/ArchivePage/components/ArchievedLeads'))
// );

// const ArchievedDeals = Loadable(
// 	lazy(() => import('components/BusinessModule/pages/ArchivePage/components/ArchievedDeals'))
// );

// const ArchievedOrders = Loadable(
// 	lazy(() => import('components/BusinessModule/pages/ArchivePage/components/ArchievedOrders'))
// );
