import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	Timestamp,
	addDoc,
	arrayUnion,
	collection,
	doc,
	getDoc,
	getDocs,
	getFirestore,
	onSnapshot,
	orderBy,
	query,
	setDoc,
	updateDoc,
	where,
	writeBatch,
} from 'firebase/firestore';
import '../../../firebase';
import {
	setDealsStatuses,
	setActiveDeal,
	setExchangeRate,
	setDeals,
	setDealsList,
	setMoreOnlineResponsiblePersons,
	setArchivedDeals,
} from './slice';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { message } from 'utils/GlobalComponent';

const db = getFirestore();

function unique(arr) {
	let uniqueId = [];
	let result = [];

	for (let str of arr) {
		if (!uniqueId.includes(str.id)) {
			uniqueId.push(str.id);
			result.push(str);
		}
	}

	return result;
}

export const getDealsStatusesThunk = createAsyncThunk(
	'deals/getDealsStatuses',
	async (dealId, { getState, rejectWithValue, dispatch }) => {
		try {
			const id = getState().business.main.activeBusiness.id;
			const collectionName = `business_collection/${id}/deals_statuses`;

			const q = query(collection(db, collectionName), where('id', '==', dealId));

			onSnapshot(q, async (querySnapshot) => {
				let deals = [];

				querySnapshot.forEach(async (doc) => {
					deals.push(doc.data());
				});

				const dealsStatuses = getState().business.deals.dealsStatuses;

				const allDeals = [...deals, ...dealsStatuses];

				const updDealsStatuses = unique(allDeals);

				dispatch(setDealsStatuses(updDealsStatuses));
			});
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const getDealsThunk = createAsyncThunk(
	'deals/getDeals',
	async (dealId, { getState, rejectWithValue, dispatch }) => {
		try {
			const id = getState().business.main.activeBusiness.id;
			const collectionName = `business_collection/${id}/deals`;

			const q = query(
				collection(db, collectionName),
				where('dealId', '==', dealId),
				where('archived', '==', false)
			);

			onSnapshot(q, async (querySnapshot) => {
				let deals = [];

				querySnapshot.forEach(async (doc) => {
					deals.push(doc.data());
				});

				dispatch(setDeals(deals));
			});
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const addNewDealStatusThunk = createAsyncThunk(
	'deals/addNewDealStatus',
	async ({ currentDeal, newStatusData }, { rejectWithValue, getState }) => {
		const id = getState().business.main.activeBusiness.id;
		const collectionName = `business_collection/${id}/deals_statuses`;

		const maxIndex =
			currentDeal.statuses.reduce((accumulator, value) => {
				if (value.order > accumulator) {
					accumulator = value.order;
				}
				return accumulator;
			}, 0) + 1;

		const newStatuses = [
			...currentDeal.statuses,
			{ id: maxIndex, order: maxIndex, ...newStatusData },
		];

		try {
			await updateDoc(doc(db, collectionName, currentDeal.id), {
				statuses: newStatuses,
			});

			message.success('Новий статус успішно доданий');
		} catch (error) {
			message.error('Сталася помилка при додаванні нового статусу');

			return rejectWithValue(error.message);
		}
	}
);

export const deleteDealStatusThunk = createAsyncThunk(
	'deals/deleteDealStatusThunk',
	async ({ dealId, statusId }, { getState, rejectWithValue }) => {
		try {
			const state = getState();
			const idBusiness = state?.business?.main?.activeBusiness?.id;

			if (!idBusiness) {
				throw new Error('Ідентифікатор бізнесу не знайдено.');
			}

			const collectionName = `business_collection/${idBusiness}/deals_statuses`;

			const dealsStatuses = state?.business?.deals?.dealsStatuses;

			if (!Array.isArray(dealsStatuses)) {
				throw new Error('Дані про статуси угод відсутні.');
			}

			const currentDeal = dealsStatuses.find((deal) => deal.id === dealId);

			if (!currentDeal) {
				throw new Error('Угода з таким ідентифікатором не знайдена.');
			}

			const updatedDealStatuses = currentDeal.statuses.filter((status) => status.id !== statusId);

			await updateDoc(doc(db, collectionName, dealId), {
				statuses: updatedDealStatuses,
			});

			message.success('Статус угоди успішно видалено');
		} catch (error) {
			console.error('Ошибка при удалении статуса:', error);
			message.error(`Виникла помилка при видаленні статусу: ${error.message}`);
			return rejectWithValue(error.message);
		}
	}
);

export const createNewDealThunk = createAsyncThunk(
	'deals/createNewDeal',
	async (newDealName, { rejectWithValue, getState }) => {
		const id = getState().business.main.activeBusiness.id;
		const currentDealsNames = getState().business.main.activeBusiness.dealsNames;

		const dealsCollectionName = `business_collection/${id}/deals_statuses`;
		const tasksStatusesName = `business_collection/${id}/tasks_statuses`;

		const defaulDataFromCrmsCollections = {
			statuses: [],
		};
		try {
			const docRef = await addDoc(
				collection(db, dealsCollectionName),
				defaulDataFromCrmsCollections
			);

			if (docRef) {
				await updateDoc(doc(db, dealsCollectionName, docRef.id), {
					id: docRef.id,
					createdAt: Timestamp.fromDate(new Date()),
				});

				await setDoc(doc(db, tasksStatusesName, docRef.id), {
					...defaulDataFromCrmsCollections,
					id: docRef.id,
					createdAt: Timestamp.fromDate(new Date()),
				});

				const newDeals = [...currentDealsNames, { id: docRef.id, name: newDealName }];

				await updateDoc(doc(db, 'business_collection', id), {
					dealsNames: newDeals,
				});

				message.success('Нова угода успішно додана');
			}
		} catch (error) {
			message.error('Сталася помилка при додаванні нової угоди');

			return rejectWithValue(error.message);
		}
	}
);

export const getActiveDealLeadThunk = createAsyncThunk(
	'deals/getActiveDealLead',
	async (leadId, { getState, rejectWithValue, dispatch }) => {
		try {
			if (leadId === null || leadId === 'new-lead') return dispatch(setActiveDeal(null));

			const id = getState().business.main.activeBusiness.id;
			const collectionName = `business_collection/${id}/deals`;
			const q = query(collection(db, collectionName), where('id', '==', leadId));

			onSnapshot(q, async (querySnapshot) => {
				let deal = [];
				querySnapshot.forEach(async (doc) => {
					deal.push(doc.data());
				});

				dispatch(setActiveDeal(deal[0]));
			});
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const addEditDealLeadThunk = createAsyncThunk(
	'deals/addEditDealLead',
	async ({ leadId, data, dealId }, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness.id;

			const collectionName = `business_collection/${idBusiness}/deals`;

			if (leadId === 'new-lead') {
				const commentRef = collection(db, collectionName);
				const docRef = await addDoc(commentRef, data);

				if (docRef) {
					await updateDoc(doc(db, collectionName, docRef.id), {
						id: docRef.id,
						dealId,
						createdAt: Timestamp.fromDate(new Date()),
						changedLeadData: data.changedLeadData,
					});

					message.success('Новий лід в угоду успішно доданий');
				}
			} else {
				await updateDoc(doc(db, collectionName, leadId), {
					...data,
					changedLeadData: arrayUnion(...data.changedLeadData),
				});
				message.success('Лід успішно оновлено');
			}
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const fetchDealsThunk = createAsyncThunk(
	'deals/fetchDeals',
	async (_, { getState, rejectWithValue, dispatch }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness.id;

			if (!idBusiness) {
				throw new Error('Відсутній ID бізнесу');
			}

			const dealsCollection = collection(db, `/business_collection/${idBusiness}/deals`);
			const dealsSnapshot = await getDocs(dealsCollection);

			const dealsList = dealsSnapshot.docs.map((doc) => {
				const dealData = doc.data();

				const nameField = dealData.aboutLead?.find((item) => item.key === 'name');
				const name = nameField?.value || 'Без назви';

				return {
					id: doc.id,
					name,
					dealId: doc.id,
					sourceType: dealData.sourceType || 'Невідомо',
				};
			});

			dispatch(setDealsList(dealsList));

			return dealsList;
		} catch (error) {
			console.error('Помилка при отриманні угод:', error);
			return rejectWithValue(error.message);
		}
	}
);

export const fetchArchivedDealsThunk = createAsyncThunk(
	'deals/fetchArchivedDeals',
	async (_, { getState, dispatch, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness.id;

			if (!idBusiness) {
				throw new Error('Відсутній ID бізнесу');
			}

			console.log('ID бизнеса:', idBusiness);

			const dealsCollection = collection(db, `/business_collection/${idBusiness}/deals`);
			const dealsSnapshot = await getDocs(dealsCollection);

			if (dealsSnapshot.empty) {
				console.warn('Коллекция deals пуста');
			}

			const archivedDeals = dealsSnapshot.docs
				.map((doc) => {
					const dealData = doc.data();
					console.log('Данные сделки:', dealData);

					if (dealData.archived) {
						const nameField = dealData.aboutLead?.find((item) => item.key === 'name');
						const name = nameField?.value || 'Без назви';

						return {
							id: doc.id,
							name,
							sourceType: dealData.sourceType || 'Невідомо',
							sum: dealData.sum || 0,
						};
					}
					return null;
				})
				.filter(Boolean);

			if (archivedDeals.length === 0) {
				console.warn('Не найдено архивированных сделок');
			}

			dispatch(setArchivedDeals(archivedDeals));

			return archivedDeals;
		} catch (error) {
			console.error('Помилка при отриманні архівованих угод:', error);
			return rejectWithValue(error.message);
		}
	}
);

export const dearchiveDealThunk = createAsyncThunk(
	'deals/dearchiveDeal',
	async (dealId, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness.id;

			if (!idBusiness) {
				throw new Error('Відсутній ID бізнесу');
			}

			const dealDocRef = doc(db, `/business_collection/${idBusiness}/deals`, dealId);

			await updateDoc(dealDocRef, { archived: false });

			return dealId;
		} catch (error) {
			console.error('Помилка деархівації угоди:', error);
			return rejectWithValue(error.message);
		}
	}
);

export const fetchArchivedOrdersThunk = createAsyncThunk(
	'orders/fetchArchivedOrders',
	async (_, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness?.id;

			if (!idBusiness) {
				throw new Error('ID бізнесу не знайдено');
			}

			const querySnapshot = await getDocs(
				collection(db, `/business_collection/${idBusiness}/orders-new`)
			);

			const archivedOrders = querySnapshot.docs
				.map((doc) => {
					const orderData = doc.data();
					if (orderData.archived) {
						return {
							id: doc.id,
							orderNumber: orderData.orderNumber || 'Без номера',
							orderSourceNumber: orderData.orderSourceNumber || 'Немає',
							totalCost: orderData.totalCost || '0.00',
							source: orderData.source || 'Невідомо',
							status: orderData.status || 'Невідомо',
						};
					}
					return null;
				})
				.filter(Boolean);

			return archivedOrders;
		} catch (error) {
			console.error('Помилка при завантаженні архівних замовлень:', error);
			return rejectWithValue(error.message);
		}
	}
);

export const dearchiveOrderThunk = createAsyncThunk(
	'orders/dearchiveOrder',
	async (orderId, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness?.id;

			if (!idBusiness) {
				throw new Error('ID бізнесу не знайдено');
			}

			const orderDocRef = doc(db, `/business_collection/${idBusiness}/orders-new`, orderId);

			await updateDoc(orderDocRef, { archived: false });

			return orderId;
		} catch (error) {
			console.error('Помилка деархівації замовлення:', error);
			return rejectWithValue(error.message);
		}
	}
);

export const changeOrderToDealThunk = createAsyncThunk(
	'deals/changeOrderToDeal',
	async ({ data, dealId }, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness.id;
			const collectionName = `business_collection/${idBusiness}/deals`;

			const dealRef = collection(db, collectionName);
			const docRef = await addDoc(dealRef, data);

			if (docRef) {
				await updateDoc(doc(db, collectionName, docRef.id), {
					id: docRef.id,
					dealId,
					createdAt: Timestamp.fromDate(new Date()),
					isRead: false,
				});

				await updateDoc(doc(db, `business_collection/${idBusiness}/orders/${data.id}`), {
					sendDealDetails: arrayUnion({
						id: uuidv4(),
						movedAt: Timestamp.fromDate(new Date()),
					}),
				});

				message.success('Лід уcпішно переведено в угоду');

				return rejectWithValue(true);
			}
		} catch (error) {
			message.error('Виникла помилка переведення ліда в угоду');
			return rejectWithValue(false);
		}
	}
);

export const updIndexInAboutLeadBoxInDealLeadThunk = createAsyncThunk(
	'deals/updIndexInAboutLeadBoxInDealLead',
	async ({ activeOrder, data }, { rejectWithValue, dispatch }) => {
		const updAboutLead = activeOrder.aboutLead.map((field) => {
			const newFieldIndex = data.find((el) => el.id === field.id).index;

			return {
				...field,
				index: newFieldIndex,
			};
		});

		try {
			const updActiveOrder = {
				...activeOrder,
				aboutLead: updAboutLead,
			};

			dispatch(setActiveDeal(updActiveOrder));
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const updIndexInMoreBoxThunk = createAsyncThunk(
	'deals/updIndexInMoreBox',
	async ({ activeOrder, data }, { rejectWithValue, dispatch }) => {
		const changedLeadData = [];

		const updMore = activeOrder.more.map((field) => {
			const newFieldIndex = data.find((el) => el.id === field.id).index;

			if (newFieldIndex !== field.index) {
				changedLeadData.push({
					field: field.key,
					newValue: { index: newFieldIndex },
					changedAt: new Date().toISOString(),
				});
			}

			return {
				...field,
				index: newFieldIndex,
			};
		});

		try {
			const updActiveOrder = {
				...activeOrder,
				more: updMore,
				changedLeadData: [...(activeOrder.changedLeadData || []), ...changedLeadData],
			};

			dispatch(setActiveDeal(updActiveOrder));
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const saveMoreDataThunk = createAsyncThunk(
	'deals/saveMoreData',
	async ({ leadId, data }, { getState, rejectWithValue, dispatch }) => {
		console.log('leadId:', leadId);
		try {
			const idBusiness = getState().business.main.activeBusiness.id;

			const collectionName = `business_collection/${idBusiness}/deals`;

			await updateDoc(doc(db, collectionName, leadId), {
				more: data,
			});

			message.success('Дані успішно збережені');
		} catch (error) {
			message.error('Виникла помилка при збереженні даних');

			return rejectWithValue(error.message);
		}
	}
);

export const updateOnlineResponsiblePersonsThunk = createAsyncThunk(
	'crm/updateOnlineResponsiblePersons',
	async (data, { getState, rejectWithValue, dispatch }) => {
		try {
			dispatch(setMoreOnlineResponsiblePersons(data));
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const markDealLeadAsReadThunk = createAsyncThunk(
	'deals/markDealLeadAsRead',
	async (leadId, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness.id;

			const collectionName = `business_collection/${idBusiness}/deals`;

			await updateDoc(doc(db, collectionName, leadId), {
				isRead: true,
				readTime: Timestamp.fromDate(new Date()),
			});
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const archivedDealLeadThunk = createAsyncThunk(
	'deals/archivedDealLead',
	async (leadId, { getState, rejectWithValue, dispatch }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness.id;

			const collectionName = `business_collection/${idBusiness}/deals`;

			await updateDoc(doc(db, collectionName, leadId), {
				archived: true,
			});

			message.success('Лід успішно архівовано');
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const updDealLeadStatusThunk = createAsyncThunk(
	'deals/updDealLeadStatus',
	async ({ leadId, colId }, { getState, rejectWithValue, dispatch }) => {
		const idBusiness = getState().business.main.activeBusiness.id;
		const collectionName = `business_collection/${idBusiness}/deals/`;

		try {
			await updateDoc(doc(db, collectionName, leadId), {
				col_id: colId,
			});

			message.success('Статус успішно змінено');
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const updDealLeadColumnsThunk = createAsyncThunk(
	'deals/updDealLeadColumns',
	async ({ newColumns, dealId }, { getState, rejectWithValue }) => {
		const currentDeal = getState().business.deals.dealsStatuses.find((deal) => deal.id === dealId);

		const updColumns = newColumns.map((col, ind) => {
			const element = currentDeal.statuses.find((el) => el.id === Number(col.slice(-1)));

			return {
				...element,
				order: ind + 1,
			};
		});

		const idBusiness = getState().business.main.activeBusiness.id;

		const collectionName = `business_collection/${idBusiness}/deals_statuses/`;

		try {
			const batch = writeBatch(db);

			batch.update(doc(db, collectionName, dealId), { statuses: updColumns });

			await batch.commit();
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const updDealLeadIndexAndStatusDragThunk = createAsyncThunk(
	'deals/updDealLeadIndexAndStatusDrag',
	async ({ newOrders: newDeals }, { getState, rejectWithValue }) => {
		const currentDeals = getState().business.deals.deals;

		const keys = Object.keys(newDeals);

		let updDeals = [];
		for (const key of keys) {
			if (newDeals[key].length > 0) {
				newDeals[key].map((item, ind) => {
					const element = currentDeals.find((el) => el.id === item.slice(5));

					return updDeals.push({
						...element,
						col_id: Number(key.slice(-1)),
						index: ind + 1,
					});
				});
			}
		}

		try {
			const id = getState().business.main.activeBusiness.id;
			const collectionName = `business_collection/${id}/deals`;

			const batch = writeBatch(db);

			for (let v = 0; v < updDeals.length; v++) {
				batch.update(doc(db, collectionName, updDeals[v].id), updDeals[v]);
			}
			await batch.commit();
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const updateDealStatusNameThunk = createAsyncThunk(
	'deals/updateDealStatusName',
	async (data, { rejectWithValue, getState }) => {
		const { statusId, newName, color, dealId, currentDealStatuses } = data;

		const id = getState().business.main.activeBusiness.id;
		const collectionName = `business_collection/${id}/deals_statuses`;

		const newStatuses = currentDealStatuses.map((status) => {
			if (status.id === statusId)
				return {
					...status,
					name: newName,
					color,
				};

			return status;
		});

		try {
			await updateDoc(doc(db, collectionName, dealId), {
				statuses: newStatuses,
			});

			message.success('Cтатус успішно оновлено');
		} catch (error) {
			message.error('Сталася помилка при оновденні статусу');

			return rejectWithValue(error.message);
		}
	}
);

export const saveAboutLeadDealThunk = createAsyncThunk(
	'deals/saveAboutLeadDeal',
	async ({ leadId, data }, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness.id;

			const collectionName = `business_collection/${idBusiness}/deals`;

			await updateDoc(doc(db, collectionName, leadId), {
				aboutLead: data,
			});

			message.success('Дані успішно збережені');
		} catch (error) {
			message.error('Виникла помилка при збереженні даних');

			return rejectWithValue(error.message);
		}
	}
);

export const updDealLeadInfoInAboutLeadBlockThunk = createAsyncThunk(
	'deals/updDealLeadInfoInAboutLeadBlock',
	async (data, { getState, rejectWithValue, dispatch }) => {
		const { leadId, typeAction, fieldId, newNameBlock } = data;

		if (leadId === 'new-lead')
			return message.error('Замволення нове та ще не збережене. Спочатку збережіть замовлення');

		const updField =
			typeAction === 'changeName'
				? { label: newNameBlock }
				: { visible: typeAction === 'isVisible' ? true : false };

		try {
			const activeDeal = getState().business.deals.activeDeal;

			const updateAboutLead = activeDeal?.aboutLead.map((field) => {
				if (field.id === fieldId) {
					return {
						...field,
						...updField,
					};
				} else {
					return field;
				}
			});

			const updActiveDeal = {
				...activeDeal,
				aboutLead: updateAboutLead,
			};

			dispatch(setActiveDeal(updActiveDeal));
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const deleteDealThunk = createAsyncThunk(
	'deals/deleteDeal',
	async (dealId, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness.id;
			const currentDeals = getState().business.main.activeBusiness.dealsNames;

			const updateDeals = currentDeals.filter((el) => el.id !== dealId);

			await updateDoc(doc(db, 'business_collection', idBusiness), {
				dealsNames: updateDeals,
			});

			message.success('Угоду успішно видалено');

			return rejectWithValue(true);
		} catch (error) {
			message.error(error.message);
			message.success('Виникла помилка при видаленні угоди:', error.message);

			return rejectWithValue(false);
		}
	}
);

export const archiveDealThunk = createAsyncThunk(
	'deals/archiveDeal',
	async (dealId, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness.id;
			const archiveDetail = {
				id: dealId,
				archivedAt: new Date(),
			};

			await updateDoc(doc(db, 'business_collection', idBusiness), {
				deals: arrayUnion(archiveDetail),
			});

			message.success('Угоду успішно архівовано');
			return dealId;
		} catch (error) {
			message.error('Помилка під час архівування угоди');
			return rejectWithValue(error.message);
		}
	}
);

export const saveNewDealNameThunk = createAsyncThunk(
	'deals/saveNewDealName',
	async ({ dealId, newDealName }, { getState, rejectWithValue, dispatch }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness.id;
			const currentDeals = getState().business.main.activeBusiness.dealsNames;

			const updateDeals = currentDeals.map((el) => {
				if (el.id === dealId) {
					return {
						...el,
						name: newDealName,
					};
				}
				return el;
			});

			await updateDoc(doc(db, 'business_collection', idBusiness), {
				dealsNames: updateDeals,
			});

			message.success('Назву угоди успішно оновлено');

			return rejectWithValue(true);
		} catch (error) {
			message.error(error.message);
			message.success('Виникла помилка при оновленні назви:', error.message);

			return rejectWithValue(false);
		}
	}
);
