import { createSlice } from '@reduxjs/toolkit';
import { handleCurrentRoles } from './helpers';

const permissionsSlice = createSlice({
	name: 'business/permissions',
	initialState: {
		currentRoles: null,
	},
	reducers: {
		setCurrentRoles: handleCurrentRoles,
	},
});

export const PermissionsReducer = permissionsSlice.reducer;
export const { setCurrentRoles } = permissionsSlice.actions;
