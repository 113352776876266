import { createSlice } from '@reduxjs/toolkit';
import {
	addNewCategory,
	addNewCharacteristic,
	addNewCharacteristicGroup,
	addNewManufacturer,
	deleteCategory,
	deleteCharacteristic,
	deleteCharacteristicGroup,
	editCharacteristic,
	editCharacteristicGroup,
	fetchCategories,
	fetchCharacteristicGroups,
	fetchCharacteristics,
	fetchManufacturers,
	updateCategory,
} from '../crm/thunks';

const GoodsSlice = createSlice({
	name: 'business/goods',
	initialState: {
		activeGoods: null,
		forConfirmation: null,
		moderation: null,
		newGoods: null,
		notActiveGoods: null,
		manufacturers: [],
		characteristics: [],
		loading: false,
		error: null,
		groups: [],
		categories: [],
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(addNewManufacturer.pending, (state) => {
				state.loading = true;
			})
			.addCase(addNewManufacturer.fulfilled, (state, action) => {
				state.manufacturers.push(action.payload);
				state.loading = false;
			})
			.addCase(addNewManufacturer.rejected, (state, action) => {
				state.error = action.payload;
				state.loading = false;
			})
			.addCase(fetchManufacturers.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchManufacturers.fulfilled, (state, action) => {
				state.manufacturers = action.payload;
				state.loading = false;
			})
			.addCase(fetchManufacturers.rejected, (state, action) => {
				state.error = action.payload;
				state.loading = false;
			})
			.addCase(addNewCharacteristic.pending, (state) => {
				state.loading = true;
			})
			.addCase(addNewCharacteristic.fulfilled, (state, action) => {
				state.characteristics.push(action.payload);
				state.loading = false;
			})
			.addCase(addNewCharacteristic.rejected, (state, action) => {
				state.error = action.payload;
				state.loading = false;
			})
			.addCase(fetchCharacteristics.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchCharacteristics.fulfilled, (state, action) => {
				state.characteristics = action.payload;
				state.loading = false;
			})
			.addCase(fetchCharacteristics.rejected, (state, action) => {
				state.error = action.payload;
				state.loading = false;
			})
			.addCase(editCharacteristic.fulfilled, (state, action) => {
				const index = state.characteristics.findIndex(
					(characteristic) => characteristic.id === action.payload.id
				);
				if (index !== -1) {
					state.characteristics[index] = action.payload;
				}
			})
			.addCase(deleteCharacteristic.fulfilled, (state, action) => {
				state.characteristics = state.characteristics.filter(
					(characteristic) => characteristic.id !== action.payload
				);
			})
			.addCase(addNewCharacteristicGroup.pending, (state) => {
				state.loading = true;
			})
			.addCase(addNewCharacteristicGroup.fulfilled, (state, action) => {
				state.groups.push(action.payload);
				state.loading = false;
			})
			.addCase(addNewCharacteristicGroup.rejected, (state, action) => {
				state.error = action.payload;
				state.loading = false;
			})

			.addCase(fetchCharacteristicGroups.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchCharacteristicGroups.fulfilled, (state, action) => {
				state.groups = action.payload;
				state.loading = false;
			})
			.addCase(fetchCharacteristicGroups.rejected, (state, action) => {
				state.error = action.payload;
				state.loading = false;
			})

			.addCase(editCharacteristicGroup.fulfilled, (state, action) => {
				const index = state.groups.findIndex((group) => group.id === action.payload.id);
				if (index !== -1) {
					state.groups[index] = action.payload;
				}
			})

			.addCase(deleteCharacteristicGroup.fulfilled, (state, action) => {
				state.groups = state.groups.filter((group) => group.id !== action.payload);
			})
			.addCase(addNewCategory.pending, (state) => {
				state.loading = true;
			})
			.addCase(addNewCategory.fulfilled, (state, action) => {
				state.categories.push(action.payload);
				state.loading = false;
			})
			.addCase(addNewCategory.rejected, (state, action) => {
				state.error = action.payload;
				state.loading = false;
			})
			.addCase(fetchCategories.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchCategories.fulfilled, (state, action) => {
				console.log('Категории успешно загружены:', action.payload);

				state.categories = action.payload;
				state.loading = false;
			})
			.addCase(fetchCategories.rejected, (state, action) => {
				state.error = action.payload;
				state.loading = false;
			})
			.addCase(deleteCategory.fulfilled, (state, action) => {
				state.categories = state.categories.filter((category) => category.id !== action.payload);
			})
			.addCase(updateCategory.fulfilled, (state, action) => {
				const index = state.categories.findIndex((category) => category.id === action.payload.id);
				if (index !== -1) {
					state.categories[index] = action.payload;
				}
			});
	},
});

export const GoodsReducer = GoodsSlice.reducer;
