export const handleStaffList = (state, { payload }) => {
	state.staffList = payload;
};

export const handleIsModalAddStaffOpen = (state, { payload }) => {
	state.isModalAddStaffOpen = payload;
};

export const handleAllUsers = (state, { payload }) => {
	state.allUsers = payload;
};

export const handleCurrentUser = (state, { payload }) => {
	state.currentUser = payload;
};

export const handleStaffRequest = (state, { payload }) => {
	state.staffRequest = payload;
};

export const handleCompanyOptions = (state, { payload }) => {
	state.companyOptions = payload;
};
