export const handleActiveBusiness = (state, { payload }) => {
	state.activeBusiness = payload;
};

export const handleActiveUser = (state, { payload }) => {
	state.activeUser = payload;
};

export const handleActiveStaff = (state, { payload }) => {
	state.activeStaff = payload;
};

export const handleExchangeRate = (state, { payload }) => {
	state.exchangeRate = payload;
};
