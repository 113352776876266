import { createSlice } from '@reduxjs/toolkit';
import { fetchEventTypes, addNewEventType } from '../crm/thunks';

const eventTypesSlice = createSlice({
	name: 'eventTypes',
	initialState: {
		items: [],
		loading: false,
		error: null,
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchEventTypes.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(fetchEventTypes.fulfilled, (state, action) => {
				state.loading = false;
				state.items = action.payload;
			})
			.addCase(fetchEventTypes.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			.addCase(addNewEventType.fulfilled, (state, action) => {
				state.items.push(action.payload);
			});
	},
});

export const EventTypesReducer = eventTypesSlice.reducer;
